import AutoComplete from "@tarekraafat/autocomplete.js";
import {AutoCompleteUtils} from 'utils'

export default class Selectize {
  constructor(element, {
    dataUrl = null,
    searchAttribute = 'term',
    displayKey = ['title'],
    highlight = true,
    maxResults = Infinity,
    placeHolder = '',
    threshold = 1,
    searchEngine = 'strict',
    diacritics = false,
    inline = false,
    displayAttribute = null,
    valueAttribute = null,
    label = null,
    inputClass = null,
    submitOnSelect = false,
    data = null
  }) {
    this.selectField = element;
    this.inline = inline;
    this.displayAttribute = displayAttribute;
    this.valueAttribute = valueAttribute;
    this.element = this.createSearchField(inputClass);
    this.itemSelected = false;
    this.multiple = this.selectField.multiple === true;
    this.submitOnSelect = submitOnSelect;
    if (this.multiple) {
      this.selectedCollection = this.createSelectedCollection();
    }
    this.autocomplete = this.initAutoComplete(data, dataUrl, searchAttribute, displayKey, placeHolder, highlight, maxResults, threshold, searchEngine, diacritics);
    if (!this.multiple) {
      this.element.addEventListener('focusin', () => {
        if (!this.itemSelected) return;

        this.element.selectionStart = this.element.selectionEnd = this.element.value.length;
      })
    }

    this.selectField.style.display = 'none';

    if (label){
      this.createLabel(label);
    }

    this.element.addEventListener('keydown', event => {
      AutoCompleteUtils.bindDefaultKeyEvents(event);

      if (!this.multiple && this.itemSelected) {
        this.selectField.value = '';
        this.selectField.innerHTML = '';
        this.element.value = '';
        this.itemSelected = false;
      }
    });

    this.prefillSelectedOptions();

    return this;
  }

  initAutoComplete(data, dataUrl, searchAttribute, displayKey, placeHolder, highlight, maxResults, threshold, searchEngine, diacritics) {
    if (this.dataUrl != null) {
      data = {
        src: async () => {
          const query = this.element.value;
          const source = await fetch(`${dataUrl}?${searchAttribute}=${encodeURIComponent(query)}`);
          return await source.json();
        },
        results: list => {
          const selectedValues = [...this.selectField.selectedOptions].map(option => option.value);
          return list.filter(item => !selectedValues.includes(item.value[this.valueAttribute].toString()));
        },
        key: displayKey
      }
    } else {
      if (!data) {
        data = {
          src: [...this.selectField.options].map(option => {
            return {
              'id': option.value,
              'text': option.innerHTML
            }
          }),
          key: ['text']
        };
      };
      this.valueAttribute = 'id';
      this.displayAttribute = 'text';
    }

    new AutoComplete({
      data: data,
      selector: () => {
        return this.element;
      },
      placeHolder: placeHolder,
      highlight: highlight,
      maxResults: maxResults,
      threshold: threshold,
      searchEngine: searchEngine,
      diacritics: diacritics,
      resultsList: {
        container: source => {
          this.optionsContainer = source;
          AutoCompleteUtils.createAbsoluteDropdown(source, this.element)
        },
        destination: () => {
          return document.body;
        },
        className: 'collection autocomplete',
        element: 'div'
      },
      resultItem: {
        content: AutoCompleteUtils.createResultItem,
        element: "a",
        className: 'collection-item'
      },
      onSelection: this.onSelection.bind(this)
    })
  }


  destroy() {
    this.element.parentElement.remove();
    if (this.multiple) {
      this.selectedCollection.remove();
    }
  }

  onSelection(feedback) {
    if (this.multiple) {
      this.onSelectionMultiple(feedback);
    } else {
      this.onSelectionSingle(feedback);
    }
  }

  onSelectionSingle(feedback) {
    this.element.value = feedback.selection.value[this.displayAttribute];
    this.selectField.value = '';
    this.selectField.innerHTML = '';
    this.selectField.appendChild(this.createOption(feedback.selection.value[this.valueAttribute]));
    this.itemSelected = true;
    if (this.submitOnSelect) {
      const event = new CustomEvent('submit', {bubbles: true, cancelable: true});
      this.element.form.dispatchEvent(event);
    }
  }

  onSelectionMultiple(feedback) {
    const selectedValue = feedback.selection.value[this.valueAttribute];
    this.element.value = '';
    this.selectField.appendChild(this.createOption(selectedValue));
    this.addSelectedOptionToCollection(selectedValue, feedback.selection.value[this.displayAttribute]);
    this.element.focus();
  }

  addSelectedOptionToCollection(selectedValue, displayValue) {
    const collectionItem = document.createElement('li');
    collectionItem.classList.add('collection-item');
    collectionItem.dataset.optionValue = selectedValue;
    collectionItem.innerHTML = `
      ${displayValue}
      <a href="#!" class="secondary-content" data-turbolinks="false"><i class="material-icons red-text">clear</i></a>
    `;
    collectionItem.querySelector('a').addEventListener('click', () => {
      collectionItem.remove();
      this.selectField.querySelector(`option[value="${collectionItem.dataset.optionValue}"]`).remove();
      if (this.selectedCollection.children.length < 1) {
        this.selectedCollection.style.display = 'none';
      }
      this.element.focus();
    })
    this.selectedCollection.appendChild(collectionItem);
    this.selectedCollection.style.display = 'block';
  }

  createOption(value) {
    const option = document.createElement('option');
    option.value = value;
    option.text = '';
    option.selected = true;
    return option;
  }

  createSearchField(inputClass) {
    const searchFieldWrapper = document.createElement('div');
    searchFieldWrapper.classList.add('input-field');
    if (this.inline) {
      searchFieldWrapper.classList.add('inline');
    }

    const searchField = document.createElement('input');
    searchField.type = 'text';
    searchField.autocomplete = 'off';
    searchField.classList.add('autocomplete-input');
    if (inputClass) {
      searchField.classList.add(inputClass);
    }

    searchFieldWrapper.appendChild(searchField);
    this.selectField.parentElement.insertBefore(searchFieldWrapper, this.selectField.nextSibling);
    const label = this.selectField.labels[0]
    if (label != null) {
      const clonedLabel = label.cloneNode(true);
      searchField.id = `${this.selectField.id}-selectized`;
      clonedLabel.setAttribute('for', searchField.id);
      searchFieldWrapper.insertBefore(clonedLabel, searchField);
      label.remove();
    }

    return searchField;
  }

  createSelectedCollection() {
    const collectionWrapper = document.createElement('ul');
    collectionWrapper.classList.add('collection');
    collectionWrapper.classList.add('selectize-multiple');
    collectionWrapper.style.display = 'none';
    this.selectField.parentElement.insertBefore(collectionWrapper, this.selectField.nextSibling);
    return collectionWrapper;
  }

  prefillSelectedOptions() {
    [...this.selectField.options].forEach(option => {
      if (option.selected) {
        if (this.multiple) {
          this.addSelectedOptionToCollection(option.value, option.innerHTML);
        } else {
          this.element.value = option.innerHTML;
          this.itemSelected = true;
        }
      } else {
        option.remove();
      }
    });
  }

  createLabel(labelText){
    const labelNode = document.createElement('label');
    if(this.element.id) {
      labelNode.setAttribute('for', this.element.id);
    }
    labelNode.innerHTML = labelText;
    this.element.parentNode.insertBefore(labelNode, this.element)
  }
}
