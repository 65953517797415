import {Item} from "types/item";
import {OrderItem} from "models/order_item";
import {Product} from "types/product";
import {Customer} from "types/customer";
import {ProductGift} from "types/product_gift";
import {OrderOptions} from "types/order_options";
import {CustomerDiscount} from "types/customer_discount";

const globalAny: any = global;

export class Order {
  id: number = 0;
  customer_id: number = 0;
  date: Date = new Date('2020-01-01');
  price_total: string = "";
  status: string = "";
  address_id: number = 0;
  carrier_id: number = 0;
  note: string = "";
  payment_type: { id: number, name: string, abra_code: string } = { id: 0, name: "", abra_code: "" };
  items: Item[] = [];
  quantities: { [key: string]: number } = {};
  persisted: boolean = false;
  url: string = "";
  order_items: OrderItem[] = [];

  constructor(opts: OrderOptions | null = null) {
    if (opts != null) {
      this.id = opts.id;
      this.customer_id = opts.customer_id
      this.date = opts.date;
      this.price_total = opts.price_total;
      this.status = opts.status;
      this.address_id = opts.address_id;
      this.carrier_id = opts.carrier_id;
      this.customer_id = opts.customer_id;
      this.note = opts.note;
      this.payment_type = opts.payment_type;
      this.items = opts.items;
      this.quantities = opts.quantities;
      this.persisted = opts.persisted;
      this.url = opts.url;
      this.order_items = [];
    }
  }


  loadOrderItems(products: Product[], customer: Customer, allowZeroQuantity: boolean = false) {
    const orderItems = [] as OrderItem[];
    products.forEach((product: Product) => {
      const itemQuantity = this.quantities[product.id];
      if (itemQuantity != null && (itemQuantity > 0 || allowZeroQuantity)) {
        orderItems.push(new OrderItem(product, itemQuantity, customer, this))
      }
    });
    this.order_items = orderItems;
    this.loadOrderGifts(products, customer, allowZeroQuantity);
  }

  loadOrderGifts(products: Product[], customer: Customer, allowZeroQuantity: boolean) {
    if (customer.free_product_gifts == null) return;
    const relevantGifts = customer.free_product_gifts.filter(gift => this.productGiftAppliesToOrder(gift))

    const discountedProducts: { [key: string]: number } = {};

    relevantGifts.forEach(gift => {
      this.order_items.forEach(item => {
        if (!item.productGiftAppliesToItem(gift)) return;

        if (discountedProducts[gift.product_id] == null) discountedProducts[gift.product_id] = 0;

        discountedProducts[gift.product_id] += Math.floor((item.quantity - item.discountedQuantity()) / gift.quantity_in_set);
      })
    })
    Object.keys(discountedProducts).forEach(productId => {
      if (this.quantities[productId] != null && (this.quantities[productId] > 0 || allowZeroQuantity)) {
        const item = this.order_items.filter(orderItem => orderItem.product.id === Number.parseInt(productId))[0];

        item.quantity = item.quantity + discountedProducts[productId];
        item.gifted_quantity = discountedProducts[productId];
      } else {
        let product = products.filter(product => product.id === Number.parseInt(productId))[0];
        if (product == null) return;

        const item = new OrderItem(product, 0, customer, this);
        item.quantity = discountedProducts[productId];
        item.gifted_quantity = discountedProducts[productId];
        this.order_items.push(item);
      }
    })
    this.order_items = this.order_items.sort((itemA, itemB) => {
      if (itemA.product.code < itemB.product.code) return -1;
      if (itemA.product.code > itemB.product.code) return 1;
      return 0;
    });
  }

  itemsForDiscount(discount: CustomerDiscount): OrderItem[]{
    return this.order_items.filter(orderItem => {
      const sameDiscounts = orderItem.product.customer_discounts.filter(otherDiscount => {
        return otherDiscount.id === discount.id;
      });
      return sameDiscounts.length > 0;
    });
  }

  productGiftAppliesToOrder(gift: ProductGift): boolean {
    const relevantItems = this.order_items.filter(item => {
      const commonTags = item.product.tags.map(tag => tag.name).filter(value => {
        return gift.products.map(tag => tag.name).includes(value);
      })
      return commonTags.length > 0;
    })
    let sets = 0;
    relevantItems.forEach(item => {
      sets += Math.floor((item.quantity - item.discountedQuantity()) / gift.quantity_in_set)
    })
    return sets >= gift.minimum_sets
  }

  totalProductsPrice(): number {
    let sum = 0;
    this.order_items.forEach(orderItem => {
      sum += orderItem.discountedUnitprice() * (orderItem.quantity - orderItem.discountedQuantity())
    })
    return sum;
  }

  totalPrice(customer: Customer): number {
    return this.totalProductsPrice() + this.transportationPrice(customer) - this.additionalDiscountAmount();
  }

  totalPriceWithRounding(customer: Customer): number {
    return this.totalPrice(customer) + this.rounding();
  }

  totalPriceWithVAT(customer: Customer): number {
    return this.totalPrice(customer) + this.VATAmount(customer);
  }

  totalPriceWithVATAndRounding(customer: Customer): number {
    return this.totalPriceWithVAT(customer) + this.rounding();
  }

  transportationPrice(customer: Customer): number {
    if (this.totalProductsPrice() < this.freeTransportationLimit(customer)) {
      switch (globalAny.I18n.locale) {
        case 'cs':
          return 100;
        case 'sk':
          return 4.60
        case 'pl':
          return 40
        default:
          return 100
      }
    } else {
      return 0
    }
  }

  transportationTax(customer: Customer): number {
    let taxRate;
    switch(globalAny.I18n.locale){
      case 'cs':
        taxRate = 0.21;
        break;
      case 'sk':
        taxRate = 0.20;
        break;
      case 'pl':
        taxRate = 0;
        break;
      default:
        taxRate = 0.21;
        break;
    }
    return this.transportationPrice(customer) * taxRate;
  }

  freeTransportationLimit(customer: Customer): number {
    if (globalAny.I18n.locale === 'cs') return 4000;
    if (globalAny.I18n.locale == 'sk') return 150;
    if (globalAny.I18n.locale == 'pl') return customer.free_transport_limit;
    return 4000;
  }

  VATAmount(customer: Customer): number {
    return this.productsTax() + this.transportationTax(customer) - this.additionalDiscountTaxAmount();
  }

  productsTax():number{
    let sum = 0;
    this.order_items.forEach(orderItem => {
      sum += orderItem.discountedUnitprice() * (orderItem.quantity - orderItem.discountedQuantity()) * (Number.parseInt(orderItem.product.tax) / 100)
    })
    return sum;
  }

  additionalDiscountPercent():number{
    if(this.payment_type.abra_code != 'D1' || globalAny.I18n.locale != 'cs') return 0;

    return this.totalProductsPrice() < this.freeTransportationLimit({} as Customer) ? 0 : 3
  }

  additionalDiscountAmount(): number{
    return this.additionalDiscountPercent() / 100 * this.totalProductsPrice()
  }

  rounding(): number{
    if(globalAny.I18n.locale === 'cs' && this.payment_type.abra_code === 'D1') {
      const total = this.totalPriceWithVAT({} as Customer);
      return Math.round((Math.floor(total) - total) * 100) / 100
    }else {
      return 0
    }
  }

  additionalDiscountTaxAmount(): number{
    let amount = 0;
    const discountPercent = this.additionalDiscountPercent();

    this.order_items.forEach(orderItem => {
      const tax = orderItem.discountedUnitprice() * (orderItem.quantity - orderItem.discountedQuantity()) * (Number.parseInt(orderItem.product.tax) / 100);
      amount += Math.round(tax * discountPercent) / 100;
    })

    return amount;
  }
}
