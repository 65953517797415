import {Controller} from "stimulus";
import {JSTable} from "jstable";

export default class extends Controller {
  static values = {
    url: String
  }

  connect() {
    this.originalHTML = this.element.innerHTML
    this.datatable = new JSTable(this.table(), {
      labels: {
        perPage: `${I18n.t('datatables.list_records')}: {select}`,
        noRows: I18n.t('datatables.no_data_in_table'),
        placeholder: I18n.t('datatables.search'),
        info: `${I18n.t('datatables.listing')} {start} ${I18n.t('datatables.up_to')} {end} ${I18n.t('datatables.from')} {rows} ${I18n.t('datatables.records')}`,
        infoFiltered: `${I18n.t('datatables.listing')} {start} ${I18n.t('datatables.up_to')} {end} ${I18n.t('datatables.from')} {rows} ${I18n.t('datatables.records')}`,
        loading: I18n.t('datatables.loading')
      },
      layout: {
        top: '{search}{select}',
        bottom: '{info}{pager}'
      },
      perPage: 20,
      perPageSelect: [10, 20, 50, 100],
      searchable: true,
      serverSide: this.hasUrlValue,
      searchDelay: 500,
      ajax: this.baseUrl(),
      ajaxParams: this.searchParams()
    });

    M.FormSelect.init(this.element.querySelector('.dt-dropdown select'))
  }

  disconnect() {
    this.element.innerHTML = this.originalHTML
  }

  // private

  table() {
    return this.element.querySelector('table')
  }

  baseUrl() {
    if (this.hasUrlValue) {
      return this.urlValue.split('?')[0]
    } else {
      return null
    }
  }

  searchParams() {
    if (this.hasUrlValue) {
      const params = new URLSearchParams(this.urlValue.split('?')[1])
      return Object.fromEntries(params)
    } else {
      return null
    }
  }
}
