import { Controller } from 'stimulus'
import Selectize from "../selectize";

export default class extends Controller {
  static values = {
    url: String,
    searchAttribute: String,
    displayKey: String,
    highlight: Boolean,
    maxResults: Number,
    placeHolder: String,
    threshold: Number,
    searchEngine: String,
    diacritics: Boolean,
    inline: Boolean,
    displayAttribute: String,
    valueAttribute: String,
    label: String,
    inputClass: String,
    submitOnSelect: Boolean,
    data: Object
  }


  connect() {
    const searchAttribute = this.hasSearchAttributeValue ? this.searchAttributeValue : undefined;
    const displayKey = this.hasDisplayKeyValue ? [this.displayKeyValue] : undefined;
    const highlight = this.hasHighlightValue ? this.highlightValue : undefined;
    const maxResults = this.hasMaxResultsValue ? this.maxResultsValue : undefined;
    const placeHolder = this.hasPlaceHolderValue ? this.placeHolderValue : undefined;
    const threshold = this.hasThresholdValue ? this.thresholdValue : undefined;
    const searchEngine = this.hasSearchEngineValue ? this.searchEngineValue : undefined;
    const diacritics = this.hasDiacriticsValue ? this.diacriticsValue : undefined;
    const inline = this.hasInlineValue ? this.inlineValue : undefined;
    const label = this.hasLabelValue ? this.labelValue : undefined;
    const dataUrl = this.hasUrlValue ? this.urlValue : undefined;
    const displayAttribute = this.hasDisplayAttributeValue ? this.displayAttributeValue : undefined;
    const valueAttribute = this.hasValueAttributeValue ? this.valueAttributeValue : undefined;
    const inputClass = this.hasInputClassValue ? this.inputClassValue : undefined;
    const submitOnSelect = this.hasSubmitOnSelectValue ? this.submitOnSelectValue : false;
    const data = this.hasDataValue ? this.dataValue : undefined;

    this.selectize = new Selectize(this.element, {
      dataUrl: dataUrl,
      searchAttribute: searchAttribute,
      displayKey: displayKey,
      highlight: highlight,
      maxResults: maxResults,
      placeHolder: placeHolder,
      threshold: threshold,
      searchEngine: searchEngine,
      diacritics: diacritics,
      inline: inline,
      displayAttribute: displayAttribute,
      valueAttribute: valueAttribute,
      label: label,
      inputClass: inputClass,
      submitOnSelect: submitOnSelect,
      data: data
    })
    M.updateTextFields()
  }

  disconnect(){
    this.selectize.destroy();
  }
}
