import { Controller } from 'stimulus'
import Flash from 'flash'

export default class extends Controller {
  replace(event) {
    this.element.closest('tr').outerHTML = event.detail[2].response
  }

  error(event) {
    Flash.error(event.detail[2].response)
  }
}
