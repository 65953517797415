// Load all the controllers within this directory and all subdirectories. 
// Controller files must be named *_controller.js.

import { Application } from "stimulus"
import CustomRemoteController from './custom_remote_controller'
import Sortable from 'stimulus-sortable'
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()

application.register('remote', CustomRemoteController)
application.register('sortable', Sortable)

const context = require.context("controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context))
