import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['offerModal']

  connect(){
    this.offerModalTargets.forEach(modal => {
      const allCustomers = modal.querySelectorAll('.customer-select input')[1];
      const customerTags = modal.querySelector('.customer-tags input');
      modal.querySelector('form').addEventListener('submit', event => {
        modal.querySelectorAll('[data-chips-tags-value]').forEach( item => {
          const instance = M.Chips.getInstance(item);
          customerTags.value = instance.chipsData.map(x => x.tag).join(', ');
        });
        if (!allCustomers.checked && customerTags.value === '') {
          M.toast({html: I18n.t('all_customers_or_tags_needed'), classes: 'red'});
          event.preventDefault();
        }
      });
    });
  }
}
