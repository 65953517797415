import {Controller} from 'stimulus';
import {createApp} from "vue";
import order_summary from "../../components/order_summary";
import Turbolinks from "turbolinks";

export default class extends Controller{
  static values = {
    orderUrl: String,
    customerUrl: String,
    productsUrl: String,
    carriers: Array,
    paymentTypes: Array
  }

  connect(){
    this.elementHTML = this.element.innerHTML;
    this.vueApp = createApp(order_summary, {
      orderUrl: this.orderUrlValue,
      customerUrl: this.customerUrlValue,
      productsUrl: this.productsUrlValue,
      carriers: this.carriersValue,
      paymentTypes: this.paymentTypesValue
    });
    this.vueApp.mount(this.element);
  }

  disconnect(){
    this.element.innerHTML = this.elementHTML;
  }
}
