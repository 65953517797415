import { Controller } from 'stimulus'

export default class extends Controller{
  static targets = ['adminRow']

  connect(){
    this.adminRowTargets.forEach( adminRow => {
      adminRow.addEventListener('click', event => {
        let target = event.currentTarget;
        let adminId = target.dataset.adminId;
        let dateStart = target.dataset.dateStart;
        let dateEnd = target.dataset.dateEnd;
        Turbolinks.visit(`/ta_reports/products_for_admin?admin_id=${adminId}&date_start=${dateStart}&date_end=${dateEnd}`);
      })
    })
  }
}
