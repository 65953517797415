
import {defineComponent} from "vue";
import vueSearch from 'components/search';
import vueMaterializeTag from 'components/materialize_tag';
import vuePreloader from 'components/preloader';
import {Customer} from "types/customer";
import {AccessControl} from "access_control";
import {mattDestroy, mattInit} from "matt_init";
import moment from "moment";

const globalAny: any = global;

export default defineComponent({
  props: ['customersUrl'],
  components: {
    vueSearch,
    vueMaterializeTag,
    vuePreloader
  },
  methods: {
    t(arg: string, opts?: any) {
      return globalAny.I18n.t(arg, opts)
    },
    loadCustomersInfo() {
      fetch(this.customersUrl, {
        method: 'GET',
        headers: {
          'Accept': 'application/json'
        },
        cache: 'no-cache'
      }).then(response => response.json()).then((data: Customer[]) => {
        this.customers = data;
        this.filteredCustomers = this.customers;
      }).catch(error => {
        console.error(error);
      })
    },
    lastOrderDate(customer: Customer): string {
      if (customer.last_order_date) {
        const date = moment(customer.last_order_date);
        return date.format('DD.MM.YY');
      } else {
        return ' - '
      }
    },
    updateFilteredCustomers(event: any) {
      this.filteredCustomers = event.searchResults;
    },
    showAllCustomers() {
      this.filteredCustomers = this.customers;
    }
  },
  data() {
    return {
      customers: [] as Customer[],
      filteredCustomers: [] as Customer[]
    }
  },
  mounted() {
    this.loadCustomersInfo()
  },
  setup() {
    const accessControl = new AccessControl();
    return accessControl.componentSetup();
  },
  beforeUpdate() {
    let el = this.$el;
    while (el != null && !el.querySelectorAll) {
      el = el.parentElement;
    }
    if (el == null) return;

    mattDestroy(el);
  },
  updated() {
    let el = this.$el;
    while (el != null && !el.querySelectorAll) {
      el = el.parentElement;
    }
    if (el == null) return;

    mattInit(el);
  }
})
