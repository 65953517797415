import {Controller} from 'stimulus';
import M from 'materialize-css';

export default class extends Controller {
  static targets = ['hiddenInput', 'chips']

  parseTags() {
    const instance = M.Chips.getInstance(this.chipsTarget);
    this.hiddenInputTarget.value = instance.chipsData.map(x => x.tag).join(', ');
  }
}
