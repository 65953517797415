import {Controller} from 'stimulus';
import Rails from '@rails/ujs';

export default class extends Controller {
  static values = {
    productCode: String
  }

  connect() {
    this.address = "https://api-produkty.weleda.cz";
    const productAddress = `${this.address}/products/find_by_code/${this.productCodeValue}?format=json`;

    fetch(productAddress).then(response => response.json()).then(data => {
      document.getElementById('code').textContent = data.product_code;
      document.getElementById('name').textContent = data[`name_${global.I18n.locale.replace('cs', 'cz')}`];
      document.getElementById('ean').textContent = data.ean;

      this.displayPackshot(data.packshots);
      this.callMarkdown(data[`description_${global.I18n.locale.replace('cs', 'cz')}`]);
    }).catch(error => {
      console.error(`Error fetching data from products API: ${error}`)
    })
  }

  displayPackshot(packshots) {
    if (packshots == null) return;

    const picture = document.getElementById("product_picture");
    for (let i = 0; i < packshots.length; i++) {
      if (packshots[i].language === `${I18n.locale.replace('cs', 'cz')}`) {
        picture.src = this.address + packshots[i].picture.url;
        break;
      }
    }
  }

  callMarkdown(description) {
    Rails.ajax({
      type: 'GET',
      url: '/welcome/markdown',
      data: new URLSearchParams({text: description}),
      success: response => {
        document.getElementById("description").innerHTML = response.value;
      }
    });
  }
}
