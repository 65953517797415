import { Controller } from 'stimulus'
import {DataTable} from "simple-datatables";
import M from "materialize-css";
import {resizeCarouselTabs} from "../matt_init";

export default class extends Controller{
  static targets = ['dataTable']

  connect(){
    let tableElem = this.dataTableTarget;
    if (tableElem != null) {
      this.tableInstance = new DataTable(tableElem, {
        'columns': [
          {select: 1, sort: 'desc'},
        ],
        'labels': DataTable.labels,
        'perPage': 20,
        'perPageSelect': [20, 40, 60, 80, 100],
        'fixedColumns': false,
      });
      M.FormSelect.init(this.tableInstance.wrapper.querySelector('select'));
      let wrappingTab = this.tableInstance.wrapper.closest('.carousel-item')
      if(wrappingTab != null) {
        this.tableInstance.on('datatable.init', () => {
          resizeCarouselTabs(wrappingTab);
        });
        this.tableInstance.on('datatable.perpage', () => {
          resizeCarouselTabs(wrappingTab);
        });
        this.tableInstance.on('datatable.page', () => {
          resizeCarouselTabs(wrappingTab);
        });
      }
    }
  }

  disconnect(){
    if (this.tableInstance != null) {
      this.tableInstance.destroy();
      this.tableInstance = null;
    }
  }
}
