
import {defineComponent} from "vue";
import {OrderItem} from "models/order_item";
import {formatCurrency} from "utils";

const globalAny: any = global;

interface OrderRow {
  numRows: number;
  discountedQuantity: number;
  quantity: number;
  undiscountedQuantity: number;
  code: string;
  name: string;
  variant: string;
  unitprice: string;
  appliedDiscounts: string;
  discountedPrice: string;
  tax: string;
  totalPrice: string;
}

export default defineComponent({
  name: "vue-order-summary-table",
  props: ['order', 'customer'],
  methods: {
    t(arg: string, opts?: any) {
      return globalAny.I18n.t(arg, opts)
    },
    formatCurrency
  },
  computed: {
    orderRows(): OrderRow[] {
      if (this.order.order_items == null) return [];

      return this.order.order_items.map((orderItem: OrderItem) => {
        const discountedQuantity = orderItem.discountedQuantity();
        const discountedUnitPrice = orderItem.discountedUnitprice();

        return {
          numRows: discountedQuantity > 0 ? 2 : 1,
          discountedQuantity: discountedQuantity,
          quantity: orderItem.quantity,
          undiscountedQuantity: orderItem.quantity - discountedQuantity,
          code: orderItem.product.code,
          name: orderItem.product.name,
          variant: orderItem.product.variant,
          unitprice: this.formatCurrency(orderItem.unitprice),
          appliedDiscounts: orderItem.appliedDiscounts().map(discount => `${discount.discount}%`).join(', '),
          discountedPrice: this.formatCurrency(discountedUnitPrice),
          tax: orderItem.product.tax,
          totalPrice: this.formatCurrency(discountedUnitPrice * (orderItem.quantity - discountedQuantity))
        } as OrderRow
      });
    }
  }
})
