<template>
  <div class="row">
    <div class="col s12 m9 fixed-spaceholder">
      <vue-search :placeholder="t('selectize.start_writing_code_or_product_name')"
                  :search-data="products"
                  :search-keys="['name', 'code', 'ean']"
                  v-on:search:result="updateFilteredProducts"
                  v-on:search:clear="showAllProducts"></vue-search>
    </div>
    <div class="col s12 m3 right-align m-top-2">
      <label>
        <input type="checkbox" class="filled-in" v-model="onlyNewProducts">
        <span>{{ t('toggle_new_product') }}</span>
      </label>
    </div>
  </div>
  <div class="col s12 spaceholder">
    <table class="order-table bordered highlight">
      <thead>
      <tr>
        <th>{{ t('code') }}</th>
        <th> {{ t('product_name') }}</th>
        <th> {{ t('product_variant') }}</th>
        <th> {{ t('in_stock') }}</th>
        <th class="right-align">{{ t('your_price') }}</th>
        <th> {{ t('your_discounts') }}</th>
        <th class="quantity" v-html="t('buy_pieces')"></th>
      </tr>
      </thead>
      <tbody v-for="section in formSections">
      <tr>
        <td class="caption" :id="`cat_${section.categoryId}`" colspan="8">
          <i class="material-icons left"
             :style="{ color: section.categoryColor }">folder</i>
          {{ section.categoryName }}
        </td>
      </tr>
      <tr v-for="row in section.sectionRows" class="product-line" :id="row.code">
        <td>{{ row.code }}</td>
        <td>
          <a :href="row.detailUrl" data-remote="true" data-toggle="modal"
             data-target="#modal-product-detail">
            <i class="material-icons tiny">zoom_in</i>
            {{ row.name }}
          </a>
          <span v-if="row.new_product" class="chip new-product-badge">{{ t('new_product') }}</span>
          <div v-for="gift in row.gifts" class="chip tooltipped"
               :style="{ backgroundColor: gift.color }" data-delay="50"
               data-position="top"
               :data-tooltip="gift.description">
            {{ t('gift') }}
          </div>
          <div v-for="gift in row.productGifts" class="chip tooltipped"
               :style="{ backgroundColor: gift.color }" data-delay="50"
               data-position="top"
               :data-tooltip="gift.description">
            {{ t('gift') }}
          </div>
        </td>
        <td>
          {{ row.variant }}
        </td>
        <td>
          {{ row.quantityRange }}
        </td>
        <td class="right-align">
          {{ row.customerPrice }}
        </td>
        <td>
          <a v-for="discount in row.discounts" :href="row.explainDiscountUrl"
             target="_blank"
             class="btn btn-discount waves-effect waves-light z-depth-0 tooltipped"
             data-position="top" :data-tooltip="discount.tooltip"
             :class="discount.colorClass">
            {{ discount.discount }}
            <i class="material-icons">remove_red_eye</i>
          </a>
        </td>
        <td class="quantity">
          <template v-if="order != null">
            <div class="input-group" v-if="row.inStock">
              <button type="button" class="btn blue-grey lighten-3 button-plus-minus"
                      @click="subtractQuantity(row.id)">
                -
              </button>
              <input type="number" :name="`buy[${row.id}]`" :max="row.stock" min="0"
                     placeholder="0" class="item-quantity"
                     v-model.number="order.quantities[row.id]">
              <button type="button" class="btn blue-grey lighten-3 button-plus-minus"
                      @click="addQuantity(row.id, row.stock)">
                +
              </button>
            </div>
            <template v-else>
              {{ t('not_in_stock') }}
            </template>
          </template>
        </td>
      </tr>
      </tbody>
      <tbody v-if="products.length === 0">
      <tr>
        <td colspan="8" class="center-align">
          <vue-preloader></vue-preloader>
        </td>
      </tr>
      </tbody>
      <tfoot>
      <tr>
        <td colspan="4"></td>
        <td class="total-quantity" colspan="3">
          {{ t('total_pcs') }}:&nbsp;
          <span>
            <input id="total-quantity" readonly="readonly" type="text" :value="totalQuantity">
          </span>
        </td>
      </tr>
      </tfoot>
    </table>
  </div>
  <div class="col s12 sticky" id="sticky-submit">
    <div class="row">
      <template v-if="order.persisted">
        <div class="col s4">
          <a :href="order.url" class="btn btn-small waves-effect waves-light red lighten-1"
             data-method="delete">
            <i class="material-icons left">close</i>
            {{ t('start_order_again') }}
          </a>
          <template v-if="customer.email === 'jl@chl.cz'">
            <br>
            <a :href="customer.import_pdf_url"
               class="btn btn-small waves-effect waves-light amber lighten-1 m-top-1">
              <i class="material-icons left">picture_as_pdf</i>
              {{ t('import_pdf_for_customer') }}
            </a>
          </template>
        </div>
        <div class="col s4 center-align">
          {{ t('editing_order') }}
          <strong>{{ order.id }}</strong>
          <template v-if="order.customer_id !== currentCustomer.id">
            {{ ` ${t('for_custumer')}` }}
            <select class="material" name="order[customer_id]" id="order_customer_id" tabindex="-1"
                    @change="switchOrderCustomer">
              <option v-for="customerOption in customers" :value="customerOption.id"
                      :selected="customerOption.id === order.customer_id"
                      :data-url="customerOption.url">
                {{ customerOption.name_or_company }}
              </option>
            </select>
          </template>
        </div>
        <div class="col s4 right-align" :class="{'offset-s8': !order.persisted}">
          <a class="btn btn-small waves-effect waves-light right modal-trigger"
             href="#summary_modal">
            {{ t('order_now') }}
            <i class="material-icons right">shopping_cart</i>
          </a>
        </div>
      </template>
    </div>
  </div>
  <div id="summary_modal" class="modal bottom-sheet">
    <div class="modal-content">
      <vue-order-summary-table :order="order" :customer="customer"></vue-order-summary-table>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import VueSearch from "./search";
import {Customer} from "types/customer";
import {Category} from "types/category";
import {Product} from "types/product";
import {AccessControl} from "access_control";
import {Order} from "models/order";
import {Gift} from "types/gift";
import {mattDestroy, mattInit} from "matt_init";
import VuePreloader from "./preloader";
import VueOrderSummaryTable from "./order_summary_table";
import {OrderItem} from "models/order_item";
import {ProductGift} from "types/product_gift";
import {CustomerDiscount} from "types/customer_discount";
import {formatCurrency} from "utils";
import {OrderOptions} from "types/order_options";

const globalAny: any = global;

interface CategoryProduct {
  category: Category,
  products: Product[]
}

interface OrderSection {
  categoryId: number;
  categoryColor: string;
  categoryName: string;
  sectionRows: SectionRow[];
}

interface SectionRow {
  id: number;
  code: string;
  ean: string;
  name: string;
  variant: string;
  gifts: { color: string, description: string }[];
  productGifts: { color: string, description: string }[];
  quantityRange: string;
  customerPrice: string;
  explainDiscountUrl: string;
  detailUrl: string;
  discounts: { colorClass: string; discount: string; tooltip: string; }[]
  inStock: boolean;
  stock: number;
}

export default defineComponent({
  components: {VueOrderSummaryTable, VuePreloader, VueSearch},
  props: ['categoriesUrl', 'productsUrl', 'orderUrl', 'customerUrl', 'customers'],
  name: "order_form",
  methods: {
    t(arg: string, opts?: any) {
      return globalAny.I18n.t(arg, opts)
    },
    loadOrderData() {
      fetch(this.categoriesUrl, {
        headers: {'Accept': 'application/json'},
        cache: 'no-cache'
      }).then(response => response.json()).then((categories: Category[]) => {
        this.categories = categories.filter(category => category.display);
        fetch(this.productsUrl, {
          headers: {'Accept': 'application/json'}
        }).then(response => response.json()).then((products: Product[]) => {
          this.originalProducts = products;
          this.filteredProducts = this.products;
          this.buildCategoryProducts();
          this.order.loadOrderItems(this.products, this.customer);
        }).catch(error => {
          console.error(error);
        });
      }).catch(error => {
        console.error(error);
      })
      fetch(this.orderUrl, {
        headers: {'Accept': 'application/json'},
        cache: 'no-cache'
      }).then(response => response.json()).then((orderOptions: OrderOptions) => {
        this.order = new Order(orderOptions);
      });
    },
    loadCustomer(customerUrl: string) {
      fetch(customerUrl, {
        headers: {'Accept': 'application/json'},
        cache: 'no-cache'
      }).then(response => response.json()).then((customer: Customer) => {
        fetch(customer.products_url, {
          headers: {'Accept': 'application/json'}
        }).then(response => response.json()).then((products: Product[]) => {
          this.customer = customer;
          this.originalProducts = products;
          this.filteredProducts = this.products;
          this.buildCategoryProducts();
          this.order.loadOrderItems(this.products, this.customer);
        })
      })
    },
    buildCategoryProducts() {
      const categoryProducts: CategoryProduct[] = [];
      this.categories.forEach((category: Category) => {
        categoryProducts.push({
          category: category,
          products: []
        } as CategoryProduct)
      });
      this.filteredProducts.forEach((product: Product) => {
        if (!product.category_id) return;

        const categoryId = product.category_id;
        let assigned = false;

        categoryProducts.forEach((categoryProduct: CategoryProduct) => {
          if (assigned) return;

          if (categoryProduct.category.id === categoryId) {
            categoryProduct.products.push(product)
            assigned = true;
          }
        })
      });
      this.categoryProducts = categoryProducts
          .filter(categoryProduct => categoryProduct.products.length > 0);
    },
    giftsForProduct(product: Product): Gift[] {
      if (this.customer.free_gifts == null) return [];

      return this.customer.free_gifts.filter((freeGift: Gift) => {
        const commonTags = freeGift.products.map(tag => tag.name).filter(value => product.tags.map(tag => tag.name).includes(value));
        return commonTags.length > 0;
      })
    },
    productGiftsForProduct(product: Product): ProductGift[] {
      if (this.customer.free_product_gifts == null) return [];

      return this.customer.free_product_gifts.filter((freeGift: Gift) => {
        const commonTags = freeGift.products.map(tag => tag.name).filter(value => product.tags.map(tag => tag.name).includes(value));
        return commonTags.length > 0;
      })
    },
    itemForProduct(product: Product): OrderItem | null {
      const productItem = this.order.order_items.filter(item => {
        return item.product.id === product.id
      })[0];
      if (productItem != null)
        return productItem;
      return null;
    },
    updateFilteredProducts(event: any) {
      this.filteredProducts = event.searchResults;
      this.buildCategoryProducts();
    },
    showAllProducts() {
      this.filteredProducts = this.products;
      this.buildCategoryProducts();
    },
    freeGiftColor(gift: Gift, product: Product): string {
      const grey = '#bdbdbd';

      if (this.order.quantities[product.id] == null || this.order.quantities[product.id] <= 0) {
        return grey;
      }
      const orderItem = this.order.order_items.filter(item => item.product.id === product.id)[0];
      if (orderItem == null || orderItem.appliedGift() !== gift) return grey;

      return gift.badge_color;
    },
    productGiftColor(gift: ProductGift, product: Product): string {
      const grey = '#bdbdbd';

      if (this.order.quantities[product.id] == null || this.order.quantities[product.id] <= 0) {
        return grey;
      }
      const orderItem = this.order.order_items.filter(item => item.product.id === product.id)[0];
      if (orderItem != null && this.order.productGiftAppliesToOrder(gift) && orderItem.productGiftAppliesToItem(gift)) return gift.badge_color;

      return grey;
    },
    subtractQuantity(productId: number) {
      if (this.order.quantities[productId] != null && this.order.quantities[productId] > 0)
        this.order.quantities[productId] -= 1;
    },
    addQuantity(productId: number, max: number) {
      if (this.order.quantities[productId] == null) {
        this.order.quantities[productId] = 0;
      }
      if (this.order.quantities[productId] < max)
        this.order.quantities[productId] += 1;
    },
    discountColor(discount: CustomerDiscount): string {
      const discountItems = this.order.itemsForDiscount(discount);

      let combinedQuantity = 0;
      discountItems.forEach(discountItem => {
        combinedQuantity += discountItem.quantity - discountItem.gifted_quantity
      });

      if (combinedQuantity >= discount.min_num_products) {
        return 'light-green lighten-2';
      } else {
        return 'grey lighten-1'
      }
    },
    discountTooltip(discount: CustomerDiscount, productId: number) {
      if (this.order.quantities == null) return '';

      const discountItems = this.order.itemsForDiscount(discount);

      let combinedQuantity = 0;
      discountItems.forEach(discountItem => {
        combinedQuantity += discountItem.quantity - discountItem.gifted_quantity
      });

      if (combinedQuantity >= discount.min_num_products) return this.t('discount_active');
      const missingQuantity = discount.min_num_products - combinedQuantity;
      return `${this.t('buy_at_least')} ${missingQuantity} ${this.t('pluralized_products', {count: missingQuantity})} ${this.t('and_get_discount')}`
    },
    switchOrderCustomer(event: any) {
      const select: HTMLSelectElement = event.currentTarget
      const option: HTMLOptionElement = select.selectedOptions[0];
      if (option.dataset.url != null) this.loadCustomer(option.dataset.url);
    }
  },
  computed: {
    formSections(): OrderSection[] {
      return this.categoryProducts.map(categoryProduct => {
        return {
          categoryId: categoryProduct.category.id,
          categoryColor: categoryProduct.category.color_hex ? categoryProduct.category.color_hex : '#000000',
          categoryName: categoryProduct.category.name,
          sectionRows: categoryProduct.products.map(product => {
            const orderItem = this.itemForProduct(product);
            let customerPrice: number;
            if (orderItem) {
              customerPrice = orderItem.discountedUnitprice();
            } else {
              const fakeItem = new OrderItem(product, 0, this.customer, this.order)
              customerPrice = fakeItem.discountedUnitprice()
            }

            return {
              id: product.id,
              code: product.code,
              ean: product.ean,
              name: product.name,
              new_product: product.new_product,
              variant: product.variant,
              gifts: this.giftsForProduct(product).map(gift => {
                return {
                  color: this.freeGiftColor(gift, product),
                  description: gift.description_human
                }
              }),
              productGifts: this.productGiftsForProduct(product).map(gift => {
                return {
                  color: this.productGiftColor(gift, product),
                  description: gift.description_human
                }
              }),
              quantityRange: product.quantity_range,
              customerPrice: formatCurrency(customerPrice),
              explainDiscountUrl: product.explain_discount_url,
              detailUrl: product.detail_url,
              discounts: product.customer_discounts.map(discount => {
                return {
                  colorClass: this.discountColor(discount),
                  discount: discount.discount,
                  tooltip: this.discountTooltip(discount, product.id)
                }
              }),
              inStock: product.in_stock,
              stock: product.stock
            } as SectionRow
          })
        } as OrderSection
      })
    },
    totalQuantity() {
      let quantitySum = 0;

      Object.keys(this.order.quantities).forEach(productId => {
        quantitySum += this.order.quantities[productId];
      });

      return quantitySum;
    },
    products(): Product[] {
      if (this.onlyNewProducts)
        return this.originalProducts.filter(product => product.new_product);
      else
        return this.originalProducts;
    }
  },
  setup() {
    const accessControl = new AccessControl();
    return {
      ...accessControl.componentSetup()
    };
  },
  data() {
    return {
      categoryProducts: [] as CategoryProduct[],
      categories: [] as Category[],
      originalProducts: [] as Product[],
      filteredProducts: [] as Product[],
      onlyNewProducts: false,
      order: new Order(null),
      customer: {} as Customer,
      timer: null as ReturnType<typeof setTimeout> | null
    }
  },
  mounted() {
    let el = this.$el;
    while (el != null && !el.querySelectorAll) {
      el = el.parentElement;
    }
    if (el == null) return;
    el.closest('form').addEventListener('submit', this.showAllProducts);

    this.loadOrderData();
    this.order.loadOrderItems(this.products, this.customer);
    this.loadCustomer(this.customerUrl);
  },
  beforeUpdate() {
    let el = this.$el;
    while (el != null && !el.querySelectorAll) {
      el = el.parentElement;
    }
    if (el == null) return;

    mattDestroy(el);
  },
  updated() {
    let el = this.$el;
    while (el != null && !el.querySelectorAll) {
      el = el.parentElement;
    }
    if (el == null) return;

    mattInit(el);
  },
  created() {
    this.$watch(
        () => this.order.quantities,
        () => {
          if (this.timer) {
            clearTimeout(this.timer);
          }
          this.timer = setTimeout(() => {
            this.order.loadOrderItems(this.products, this.customer);
          }, 200)
        },
        {deep: true}
    )
  }
})
</script>

<style scoped>

</style>
