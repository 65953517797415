
import {defineComponent} from "vue";
import Fuse from 'fuse.js';
import IFuseOptions = Fuse.IFuseOptions;

export default defineComponent({
  name: "vue-search",
  props: ['searchName', 'placeholder', 'searchData', 'searchKeys'],
  emits: ['search:clear', 'search:result'],
  methods: {
    clearSearch() {
      this.term = '';
      this.$emit('search:clear');
    },
    emitSearchResults(searchResults: any[]){
      this.$emit('search:result', {
        searchResults: searchResults
      })
    }
  },
  watch: {
    term(newTerm) {
      if(newTerm.length === 0){
        this.clearSearch()
      }

      if (newTerm.length < 3) return;

      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        const searchResults = this.fuse.search(newTerm).map(result => result.item)
        this.emitSearchResults(searchResults)
      }, 300)
    },
    searchData(newData) {
      this.fuse = new Fuse(newData, this.fuseOptions)
      if(this.term.length === 0){
        this.clearSearch()
      } else {
        const searchResults = this.fuse.search(this.term).map(result => result.item)
        this.emitSearchResults(searchResults)
      }
    }
  },
  data() {
    return {
      term: '',
      timer: null as ReturnType<typeof setTimeout> | null
    }
  },
  setup(props) {
    const fuseOptions: IFuseOptions<unknown> = {
      threshold: 0.2,
      keys: props.searchKeys
    }
    const fuse = new Fuse(props.searchData, fuseOptions)
    return {
      fuse,
      fuseOptions
    }
  }
})
