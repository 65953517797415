<template>
  <div class="container">
    <div class="row m-bottom-1">
      <div class="col s12">
        <h5>
          <i class="material-icons left">shopping_cart</i>
          {{ t('your_cart') }}
          <button type="submit" class="btn btn-small waves-effect waves-light right" :disabled="orderRows.length === 0">
            {{ t('order_next_step') }}
            <i class="material-icons right">chevron_right</i>
          </button>
        </h5>
      </div>
    </div>
    <div class="row">
      <div class="col s12">
        <table class="order-summary-table bordered">
          <thead>
          <tr>
            <th>{{ t('code') }}</th>
            <th>{{ t('name') }}</th>
            <th>{{ t('product_variant') }}</th>
            <th class="right-align">{{ t('price') }}</th>
            <th>{{ t('your_discounts') }}</th>
            <th class="right-align">{{ t('your_price') }}</th>
            <th>{{ t('dph') }}</th>
            <th class="quantity">{{ t('total_pcs') }}</th>
            <th>{{ t('pieces_price') }}</th>
            <th class="right-align">{{ t('subtotal') }}</th>
          </tr>
          </thead>
          <tbody>
          <template v-for="row in orderRows">
            <tr>
              <td :rowspan="row.numRows">{{ row.code }}</td>
              <td :rowspan="row.numRows">{{ row.name }}</td>
              <td :rowspan="row.numRows">{{ row.variant }}</td>
              <td class="basic-price right-align">{{ row.unitprice }}</td>
              <td>
                {{ row.appliedDiscounts }}
              </td>
              <td class="right-align">{{ row.discountedPrice }}</td>
              <td>{{ row.tax }}%</td>
              <td class="quantity" :rowspan="row.numRows">
                {{ row.quantity }}
              </td>
              <td class="hide-prices">
                {{ row.undiscountedQuantity }}
              </td>
              <td class="right-align hide-prices">
                {{ row.totalPrice }}
              </td>
            </tr>
            <tr v-if="row.discountedQuantity > 0">
              <td class="basic-price right-align">{{ row.unitprice }}</td>
              <td>{{ t('gift') }}</td>
              <td class="right-align">{{ formatCurrency(0) }}</td>
              <td>{{ row.tax }}%</td>
              <td class="hide_prices">{{ row.discountedQuantity }}</td>
              <td class="right-align hide_prices">{{ formatCurrency(0) }}</td>
            </tr>
          </template>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row m-top-1">
      <div class="col s12 right-align">
        <button type="submit" class="btn btn-small waves-effect waves-light right" :disabled="orderRows.length === 0">
          {{ t('order_next_step') }}
          <i class="material-icons right">chevron_right</i>
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {OrderItem} from "models/order_item";
import {formatCurrency} from "utils";

const globalAny: any = global;

interface OrderRow {
  numRows: number;
  discountedQuantity: number;
  quantity: number;
  undiscountedQuantity: number;
  code: string;
  name: string;
  variant: string;
  unitprice: string;
  appliedDiscounts: string;
  discountedPrice: string;
  tax: string;
  totalPrice: string;
}

export default defineComponent({
  name: "vue-order-summary-table",
  props: ['order', 'customer'],
  methods: {
    t(arg: string, opts?: any) {
      return globalAny.I18n.t(arg, opts)
    },
    formatCurrency
  },
  computed: {
    orderRows(): OrderRow[] {
      if (this.order.order_items == null) return [];

      return this.order.order_items.map((orderItem: OrderItem) => {
        const discountedQuantity = orderItem.discountedQuantity();
        const discountedUnitPrice = orderItem.discountedUnitprice();

        return {
          numRows: discountedQuantity > 0 ? 2 : 1,
          discountedQuantity: discountedQuantity,
          quantity: orderItem.quantity,
          undiscountedQuantity: orderItem.quantity - discountedQuantity,
          code: orderItem.product.code,
          name: orderItem.product.name,
          variant: orderItem.product.variant,
          unitprice: this.formatCurrency(orderItem.unitprice),
          appliedDiscounts: orderItem.appliedDiscounts().map(discount => `${discount.discount}%`).join(', '),
          discountedPrice: this.formatCurrency(discountedUnitPrice),
          tax: orderItem.product.tax,
          totalPrice: this.formatCurrency(discountedUnitPrice * (orderItem.quantity - discountedQuantity))
        } as OrderRow
      });
    }
  }
})
</script>

<style scoped>

</style>
