import { Controller } from 'stimulus'

export default class extends Controller {
  static values = {
    options: Array
  }

  connect() {
    const data = {}
    this.optionsValue.forEach(option => data[option] = null)

    M.Autocomplete.init(this.element, {
      data: data
    })
  }
}
