
import {defineComponent} from "vue";
import {formatCurrency} from "utils";
import {Order} from "models/order";
import {Customer} from "types/customer";
import {OrderOptions} from "types/order_options";
import {Product} from "types/product";
import {OrderItem} from "models/order_item";
import {CustomerDiscount} from "types/customer_discount";
import {mattDestroy, mattInit} from "matt_init";
import {Address} from "types/address";

const globalAny: any = global;

interface OrderRow {
  id: number;
  numRows: number;
  discountedQuantity: number;
  quantity: number;
  undiscountedQuantity: number;
  code: string;
  name: string;
  variant: string;
  unitprice: string;
  discounts: { colorClass: string; discount: string; tooltip: string; }[];
  discountedPrice: string;
  tax: string;
  totalPrice: string;
  stock: number;
  inStock: boolean;
}

export default defineComponent({
  name: "vue-order-summary",
  props: ['customerUrl', 'orderUrl', 'productsUrl', 'carriers', 'paymentTypes'],
  methods: {
    t(arg: string, opts?: any) {
      return globalAny.I18n.t(arg, opts)
    },
    loadOrderData() {
      fetch(this.orderUrl, {
        headers: {Accept: 'application/json'},
        cache: 'no-cache'
      }).then(response => response.json()).then((orderOptions: OrderOptions) => {
        fetch(this.customerUrl, {
          headers: {Accept: 'application/json'},
          cache: 'no-cache'
        }).then(response => response.json()).then((customer: Customer) => {
          fetch(this.productsUrl, {
            headers: {Accept: 'application/json'}
          }).then(response => response.json()).then((products: Product[]) => {
            this.order = new Order(orderOptions);
            this.products = products;
            this.customer = customer;
            this.order.loadOrderItems(this.products, this.customer, true)
          })
        })
      })
    },
    subtractQuantity(productId: number) {
      if (this.order.quantities[productId] != null && this.order.quantities[productId] > 0)
        this.order.quantities[productId] -= 1;
    },
    addQuantity(productId: number, max: number) {
      if (this.order.quantities[productId] == null) {
        this.order.quantities[productId] = 0;
      }
      if (this.order.quantities[productId] < max)
        this.order.quantities[productId] += 1;
    },
    discountColor(discount: CustomerDiscount): string {
      const discountItems = this.order.itemsForDiscount(discount);

      let combinedQuantity = 0;
      discountItems.forEach(discountItem => {
        combinedQuantity += discountItem.quantity - discountItem.gifted_quantity
      });

      if (combinedQuantity >= discount.min_num_products) {
        return 'light-green lighten-2';
      } else {
        return 'grey lighten-1'
      }
    },
    discountTooltip(discount: CustomerDiscount, productId: number) {
      if (this.order.quantities == null) return '';

      const discountItems = this.order.itemsForDiscount(discount);

      let combinedQuantity = 0;
      discountItems.forEach(discountItem => {
        combinedQuantity += discountItem.quantity - discountItem.gifted_quantity
      });

      if (combinedQuantity >= discount.min_num_products) return this.t('discount_active');
      const missingQuantity = discount.min_num_products - combinedQuantity;
      return `${this.t('buy_at_least')} ${missingQuantity} ${this.t('pluralized_products', {count: missingQuantity})} ${this.t('and_get_discount')}`
    },
    paymentTypeChange(event: Event){
      const target: HTMLInputElement = event.currentTarget as HTMLInputElement;
      const name = target.dataset.name == null ? "" : target.dataset.name;
      const abraCode = target.dataset.abraCode == null ? "" : target.dataset.abraCode;

      if(target.checked){
        this.order.payment_type = { id: Number.parseInt(target.value), name: name, abra_code: abraCode }
      }
    },
    formatCurrency
  },
  computed: {
    orderRows(): OrderRow[] {
      if (this.order.order_items == null) return [];

      return this.order.order_items.map((orderItem: OrderItem) => {
        const discountedQuantity = orderItem.discountedQuantity();
        const discountedUnitPrice = orderItem.discountedUnitprice();
        const product = orderItem.product;

        return {
          id: product.id,
          numRows: discountedQuantity > 0 ? 2 : 1,
          discountedQuantity: discountedQuantity,
          quantity: orderItem.quantity,
          undiscountedQuantity: orderItem.quantity - discountedQuantity,
          code: product.code,
          name: product.name,
          variant: product.variant,
          unitprice: this.formatCurrency(orderItem.unitprice),
          discounts: product.customer_discounts.map(discount => {
            return {
              colorClass: this.discountColor(discount),
              discount: discount.discount,
              tooltip: this.discountTooltip(discount, product.id)
            }
          }),
          discountedPrice: this.formatCurrency(discountedUnitPrice),
          tax: product.tax,
          totalPrice: this.formatCurrency(discountedUnitPrice * (orderItem.quantity - discountedQuantity)),
          inStock: product.in_stock,
          stock: product.stock
        } as OrderRow
      });
    },
    freeTransportationMessage() {
      const remainingAmount = formatCurrency(this.order.freeTransportationLimit(this.customer) - this.order.totalProductsPrice());

      return `${globalAny.I18n.t('buy_for_at_least')} ${remainingAmount} ${globalAny.I18n.t('without_vat')} ${globalAny.I18n.t('receive_free_transportation')}`
    },
    customerAddresses(): Address[]{
      if(this.customer.addresses == null) return [];

      return this.customer.addresses.filter(address => !address.billing);
    },
    locale(){
      return globalAny.I18n.locale;
    },
    additionalDiscountPercent(): number{
      return this.order.additionalDiscountPercent();
    },
    additionalDiscountAmount(): number{
      return -this.order.additionalDiscountAmount();
    },
    rounding(): number{
      return this.order.rounding();
    }
  },
  data() {
    return {
      order: new Order(),
      customer: {} as Customer,
      products: [] as Product[],
      timer: null as ReturnType<typeof setTimeout> | null,
      displayAddressForm: false
    }
  },
  mounted() {
    this.loadOrderData();
  },
  beforeUpdate() {
    let el = this.$el;
    while (el != null && !el.querySelectorAll) {
      el = el.parentElement;
    }
    if (el == null) return;

    mattDestroy(el);
  },
  updated() {
    let el = this.$el;
    while (el != null && !el.querySelectorAll) {
      el = el.parentElement;
    }
    if (el == null) return;

    mattInit(el);
  },
  created() {
    this.$watch(
        () => this.order.quantities,
        () => {
          if (this.timer) {
            clearTimeout(this.timer);
          }
          this.timer = setTimeout(() => {
            this.order.loadOrderItems(this.products, this.customer, true);
          }, 200)
        },
        {deep: true}
    )
  }
})
