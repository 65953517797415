import M from 'materialize-css'

export default {
  notice(message) {
    M.toast({
      html: message,
      classes: 'teal accent-3'
    })
  },

  error(message) {
    M.toast({
      html: message,
      classes: 'red darken-1'
    })
  }
}
