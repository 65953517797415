import { Controller } from 'stimulus'
import { createApp } from "vue";
import customers from "../../components/customers";

export default class extends Controller {
  static values = {
    customersUrl: String
  }

  connect(){
    this.elementHTML = this.element.innerHTML;
    this.vueApp = createApp(customers, { customersUrl: this.customersUrlValue });
    this.vueApp.mount(this.element);
  }

  disconnect(){
    this.element.innerHTML = this.elementHTML;
  }
}
