import { RemoteController } from 'stimulus-remote'
import Flash from 'flash'

export default class CustomRemoteController extends RemoteController {
  error(event) {
    const data = event.detail[0]
    const responseCode = event.detail[1]

    // Display custom error message from server if present
    if (data.error) {
      Flash.error(data.error)
      return
    }

    // Otherwise handle error based on response code
    switch(responseCode) {
      case 'Unprocessable Entity':
        this.replace(event)
        break
      default:
        Flash.error(I18n.t('internal_server_error'))
    }
  }
}
