export default class ColorPallete {
  constructor(firstColorIndex = 1, minColor = [0, 0, 0], maxColor = [255, 255, 255]) {
    this.lastColor = firstColorIndex;
    this.colors = [[minColor, maxColor]];
    this.flattenColors = [minColor.join(','), maxColor.join(',')];
    this.lastLevel = 0;
  }



  populateLevel(level){
    this.colors[level] = [];
    this.lastLevel = level
    let previous_level_colors = this.colors[level-1]
    for(let i=0; i< (previous_level_colors.length-1); i++){
      let start_color = previous_level_colors[i]
      let end_color = previous_level_colors[i+1]
      let new_colors = this.colors[level].concat(this.getIntervalColors(start_color, end_color))
      this.colors[level] = new_colors
      let new_color_strings = []
      for (let j=0; j<new_colors.length; j++){
        new_color_strings.push(new_colors[j].join(','))
      }
      this.flattenColors = Array.from(new Set(this.flattenColors.concat(new_color_strings)))
    }
  }

  getIntervalColors(startColor, endColor){
    let xy_cross = [
      (startColor[0]+endColor[1])/2,
      (startColor[1]+endColor[0])/2,
      (startColor[2])
    ]
    let yz_cross = [
      (startColor[0]),
      (startColor[1]+endColor[2])/2,
      (startColor[2]+endColor[1])/2
    ]
    let xz_cross = [
      (startColor[0]+endColor[2])/2,
      (startColor[1]),
      (startColor[2]+endColor[0])/2
    ]
    let xy_cross2 = [
      (startColor[0]+endColor[1])/2,
      (startColor[1]+endColor[0])/2,
      (endColor[2])
    ]
    let yz_cross2 = [
      (endColor[0]),
      (startColor[1]+endColor[2])/2,
      (startColor[2]+endColor[1])/2
    ]
    let xz_cross2 = [
      (startColor[0]+endColor[2])/2,
      (endColor[1]),
      (startColor[2]+endColor[0])/2
    ]
    return [xy_cross, yz_cross, xz_cross, xy_cross2, yz_cross2, xz_cross2]
  }

  getNewColor(){
    this.lastColor += 1
    if(this.lastColor === 9){
      this.lastColor += 2;
    }
    while (this.lastColor >= this.flattenColors.length){
      this.populateLevel(this.lastLevel+1)
    }
    return this.flattenColors[this.lastColor]

  }
}
