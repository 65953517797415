import {Session} from "types/session";
import {Customer} from "types/customer";
import {ref, reactive} from 'vue';

export class AccessControl {
  sessionPromise: Promise<Session>

  constructor() {
    this.sessionPromise = this.fetchCurrentSession();
  }

  componentSetup(): object {
    const superadmin = ref(false)
    const sales = ref(false)
    const showSales = ref(false)
    const currentCustomer = reactive({} as Customer)
    this.superadmin().then(ret => superadmin.value = ret)
    this.sales().then(ret => sales.value = ret)
    this.showSales().then(ret => showSales.value = ret)
    this.customer().then((customerData: Customer|null) => {
      Object.assign(currentCustomer, customerData)
    })
    return {
      superadmin,
      sales,
      showSales,
      currentCustomer
    }
  }

  async fetchCurrentSession(): Promise<Session> {
    const response = await fetch('/current_session', {
      headers: {
        'Accept': 'application/json'
      }
    })
    return await response.json() as Session;
  }

  async superadmin(): Promise<boolean> {
    const sessionInfo = await this.sessionPromise;

    if (sessionInfo.admin) {
      return sessionInfo.admin.superadmin;
    } else {
      return false;
    }
  }

  async sales(): Promise<boolean> {
    const sessionInfo = await this.sessionPromise;

    if (sessionInfo.admin) {
      return sessionInfo.admin.sales;
    } else {
      return false;
    }
  }

  async showSales(): Promise<boolean> {
    const sessionInfo = await this.sessionPromise;

    if (sessionInfo.admin) {
      return sessionInfo.admin.show_sales;
    } else {
      return false;
    }
  }

  async customer(): Promise<Customer | null> {
    const sessionInfo = await this.sessionPromise;

    return sessionInfo.customer ? sessionInfo.customer : null
  }
}
