import {Controller} from 'stimulus'
import M from "materialize-css";

export default class extends Controller {
  static targets = ['collapsible', 'checkbox', 'chips']

  collapseAll() {
    this.collapsibleTargets.forEach(collapsible => {
      const instance = M.Collapsible.getInstance(collapsible);
      if (instance != null) instance.close();
    });
  }

  expandAll() {
    this.collapsibleTargets.forEach(collapsible => {
      const instance = M.Collapsible.getInstance(collapsible);
      if (instance != null) instance.open();
    });
  }

  toggleSelectAll(event) {
    const target = event.currentTarget;
    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = target.checked;
    })
  }

  submit(event) {
    const instance = M.Chips.getInstance(this.chipsTarget);
    const id = this.chipsTarget.id
    const hiddenFieldId = id.split('_chips')[0]
    document.querySelector(`#${hiddenFieldId}`).value =
      instance.chipsData.map(x => x.tag).join(', ')
  }
}
