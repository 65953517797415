import { Controller } from 'stimulus';
import {createApp} from "vue";
import order_form from "../../components/order_form";

export default class extends Controller{
  static values = {
    categoriesUrl: String,
    productsUrl: String,
    orderUrl: String,
    customerUrl: String,
    customers: Array
  }

  connect(){
    this.elementHTML = this.element.innerHTML;
    this.vueApp = createApp(order_form, {
      categoriesUrl: this.categoriesUrlValue,
      productsUrl: this.productsUrlValue,
      orderUrl: this.orderUrlValue,
      customerUrl: this.customerUrlValue,
      customers: this.customersValue
    });
    this.vueApp.mount(this.element);
  }

  disconnect(){
    this.element.innerHTML = this.elementHTML;
  }
}
