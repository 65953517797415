import { Controller } from 'stimulus'
import { mattInit } from "../matt_init";
import { mattDestroy } from "../matt_init";

export default class extends Controller {
  connect() {
    mattInit(this.element);
  }

  disconnect() {
    mattDestroy(this.element);
  }
}
