import {Controller} from 'stimulus'
import {fadeInEffect, fadeOutEffect} from "../utils";

export default class extends Controller {
  static targets = ['currentCarrier', 'newCarrier', 'currentAddress', 'newAddress']

  showCarrierForm() {
    fadeOutEffect(this.currentCarrierTarget, 200);
    setTimeout(() => {
        fadeInEffect(this.newCarrierTarget, 200);
      },
      220
    )
  }

  showAddressForm() {
    fadeOutEffect(this.currentAddressTarget, 200);
    setTimeout(() => {
        fadeInEffect(this.newAddressTarget, 200);
      },
      220
    )
  }
}
