<template>
  <div class="chip" v-for="tag in tags">
    {{ tag.name }}
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
  name: "vue-materialize-tag",
  props: ['tags']
});
</script>

<style scoped>

</style>
