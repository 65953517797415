import {Product} from "types/product";
import {CustomerDiscount} from "types/customer_discount";
import {Customer} from "types/customer";
import {Gift} from "types/gift";
import {Order} from "models/order";
import {ProductGift} from "types/product_gift";

export class OrderItem {
  product: Product;
  customer: Customer;
  order: Order;
  quantity: number;
  unitprice: number;
  gifted_quantity: number;

  constructor(product: Product, quantity: number, customer: Customer, order: Order) {
    this.product = product;
    this.quantity = quantity;
    this.customer = customer;
    this.order = order;
    this.gifted_quantity = 0;
    this.unitprice = Number.parseFloat(product.customer_price);
  }

  appliedDiscounts(): CustomerDiscount[] {
    return this.product.customer_discounts.filter(discount => {
      const discountItems = this.order.itemsForDiscount(discount);
      let combinedQuantity = 0;
      discountItems.forEach(discountItem => {
        combinedQuantity += discountItem.quantity - discountItem.gifted_quantity
      });
      return combinedQuantity >= discount.min_num_products
    });
  }

  productPriceCoef(): number {
    let coef = 1;
    this.appliedDiscounts().forEach(discount => {
      coef = coef * (100 - Number.parseFloat(discount.discount)) / 100;
    });
    return coef;
  }

  discountedUnitprice(): number {
    return this.unitprice * this.productPriceCoef();
  }

  discountedQuantity(): number {
    const gift = this.appliedGift();
    if (gift == null) return this.gifted_quantity;

    return Math.floor(this.quantity / (gift.bought_min_quantity + gift.gifted_quantity)) + this.gifted_quantity;
  }

  giftAppliesToOrder(gift: Gift): boolean {
    const relevantItems = this.order.order_items.filter(orderItem => {
      const commonTags = gift.products.map(tag => tag.name).filter(value => {
        return orderItem.product.tags.map(tag => tag.name).includes(value)
      })
      return commonTags.length > 0;
    })
    let sets = 0;
    relevantItems.forEach(orderItem => {
      sets += Math.floor(orderItem.quantity / gift.quantity_in_set);
    })
    return sets >= gift.minimum_sets;
  }

  productGiftAppliesToItem(gift: ProductGift): boolean {
    const commonTags = this.product.tags.map(tag => tag.name).filter(value => {
      return gift.products.map(tag => tag.name).includes(value);
    })
    return commonTags.length > 0 && (this.quantity - this.discountedQuantity()) >= gift.quantity_in_set;
  }

  appliedGift(): Gift | null {
    if (this.customer.free_gifts == null) return null;

    const appliedGift = this.customer.free_gifts.filter(freeGift => {
      const commonTags = freeGift.products.map(tag => tag.name).filter(value => {
        return this.product.tags.map(tag => tag.name).includes(value)
      })
      return commonTags.length > 0 && this.quantity >= freeGift.quantity_in_set && this.giftAppliesToOrder(freeGift);
    })[0]
    if (appliedGift == null) return null;
    return appliedGift;
  }
}
