import {Controller} from 'stimulus'
import M from 'materialize-css'
import {getOffsetTop} from "../utils";

export default class extends Controller {
  static targets = ['pin']

  connect() {
    this.instance = M.Pushpin.init(this.pinTarget, {
      top: getOffsetTop(this.pinTarget)
    });
  }

  disconnect() {
    this.instance.destroy();
  }
}
