<template>
  <vue-search :placeholder="t('selectize.start_writing_name_company_ico')" :search-data="customers"
              :search-keys="['name', 'company', 'dni', 'email']" v-on:search:result="updateFilteredCustomers"
              v-on:search:clear="showAllCustomers"></vue-search>
  <table class="orders-table bordered highlight responsive-table">
    <thead>
    <tr class="not-hide">
      <th> {{ t('name_company_shop') }}</th>
      <th> {{ t('tags') }}</th>
      <th class="right-align"> {{ t('snt_order') }}</th>
      <th class="center-align"> {{ t('addresses') }}</th>
      <th class="center-align"> {{ t('certified') }}</th>
      <th class="center-align"> {{ t('dealer') }}</th>
      <th class="center-align"> {{ t('buy_for_customer') }}</th>
      <th class="center-align"> {{ t('action') }}</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="customer in filteredCustomers" :id="customer.id">
      <td>
        {{ customer.name }}
        <template v-if="customer.company !== customer.name">
          <br>
          <em>
            {{ customer.company }}
          </em>
        </template>
        <br>
        <b v-if="customer.default_address">{{ customer.default_address.shop_name }}</b>
      </td>
      <td>
        <vue-materialize-tag :tags="customer.tags"></vue-materialize-tag>
      </td>
      <td class="right-align"> {{ lastOrderDate(customer) }}</td>
      <td class="center-align">
        <template v-if="this.superadmin">
          <a :href="customer.addresses_url" class="chip tooltipped" data-delay="50"
             data-position="top" :data-tooltip="t('total_addresses')">
            {{ customer.addresses_size }}
          </a>
          <a :href="customer.addresses_url" class="chip light-green lighten-2 tooltipped"
             data-delay="50" data-position="top" :data-tooltip="t('verified_addresses')">
            {{ customer.verified_addresses_size }}
          </a>
        </template>
        <template v-else>
          <div class="chip tooltipped" data-delay="50" data-position="top"
               :data-tooltip="t('total_addresses')">
            {{ customer.addresses_size }}
          </div>
          <div class="chip light-green lighten-2 tooltipped" data-delay="50" data-position="top"
               :data-tooltip="t('verified_addresses')">
            {{ customer.verified_addresses_size }}
          </div>
        </template>
      </td>
      <td class="center-align">
        <a v-if="this.superadmin" :href="customer.edit_url">
            <span
                :class="customer.verified ? 'boolean-true tooltipped' : 'boolean-false tooltipped'"
                data-delay="50" data-position="top"
                :data-tooltip="t('accessibility_login_info')"></span>
        </a>
        <span v-else
              :class="customer.verified ? 'boolean-true tooltipped' : 'boolean-false tooltipped'"
              data-delay="50" data-position="top"
              :data-tooltip="t('accessibility_login_info')"></span>
      </td>
      <td class="center-align">
        {{ customer.admin ? customer.admin.name : '' }}
      </td>
      <td class="center-align">
        <a :href="customer.impersonate_url" data-method="post" class="impersonate tooltipped"
           data-delay="50" data-position="top" :data-tooltip="t('become_customer')">
          <i class="material-icons left">person_pin</i>
        </a>
      </td>
      <td class="center-align">
        <span class="no-wrap" v-if="customer.admin">
            <a :href="customer.url" class="tooltipped" data-delay="50" data-position="top"
               :data-tooltip="t('view_detail')">
              <i class="material-icons">zoom_in</i>
            </a>
            <a :href="customer.edit_url" class="tooltipped" data-delay="50" data-position="top"
               :data-tooltip="t('edit')" v-if="this.superadmin">
              <i class="material-icons">mode_edit</i>
            </a>
            <span class="no-wrap" v-if="customer.verified">
              <a :href="customer.send_login_email_url" data-method="post" class="tooltipped"
                 data-delay="50" data-position="top" :data-tooltip="t('email_login_information')">
                <i class="material-icons">email</i>
              </a>
            </span>
        </span>
        <span class="no-wrap" v-else>
              <a :href="customer.claim_url" data-method="post" class="tooltipped" data-delay="50"
                 data-position="top" :data-tooltip="t('take_customer')">
                <i class="material-icons">wc</i>
              </a>
        </span>
      </td>
    </tr>
    <tr v-if="customers.length === 0">
      <td colspan="8" class="center-align">
        <vue-preloader></vue-preloader>
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import vueSearch from 'components/search';
import vueMaterializeTag from 'components/materialize_tag';
import vuePreloader from 'components/preloader';
import {Customer} from "types/customer";
import {AccessControl} from "access_control";
import {mattDestroy, mattInit} from "matt_init";
import moment from "moment";

const globalAny: any = global;

export default defineComponent({
  props: ['customersUrl'],
  components: {
    vueSearch,
    vueMaterializeTag,
    vuePreloader
  },
  methods: {
    t(arg: string, opts?: any) {
      return globalAny.I18n.t(arg, opts)
    },
    loadCustomersInfo() {
      fetch(this.customersUrl, {
        method: 'GET',
        headers: {
          'Accept': 'application/json'
        },
        cache: 'no-cache'
      }).then(response => response.json()).then((data: Customer[]) => {
        this.customers = data;
        this.filteredCustomers = this.customers;
      }).catch(error => {
        console.error(error);
      })
    },
    lastOrderDate(customer: Customer): string {
      if (customer.last_order_date) {
        const date = moment(customer.last_order_date);
        return date.format('DD.MM.YY');
      } else {
        return ' - '
      }
    },
    updateFilteredCustomers(event: any) {
      this.filteredCustomers = event.searchResults;
    },
    showAllCustomers() {
      this.filteredCustomers = this.customers;
    }
  },
  data() {
    return {
      customers: [] as Customer[],
      filteredCustomers: [] as Customer[]
    }
  },
  mounted() {
    this.loadCustomersInfo()
  },
  setup() {
    const accessControl = new AccessControl();
    return accessControl.componentSetup();
  },
  beforeUpdate() {
    let el = this.$el;
    while (el != null && !el.querySelectorAll) {
      el = el.parentElement;
    }
    if (el == null) return;

    mattDestroy(el);
  },
  updated() {
    let el = this.$el;
    while (el != null && !el.querySelectorAll) {
      el = el.parentElement;
    }
    if (el == null) return;

    mattInit(el);
  }
})
</script>
