<template>
  <div class="row">
    <div class="col s12">
      <div id="search-pin">
        <div class="row m-bottom-0">
          <div class="input-field col s12">
            <i class="material-icons prefix">search</i>
            <div class="button-wrapper">
              <button class="btn red lighten-1 z-depth-0" type="button" @click="clearSearch" :class="this.term.length > 0? '' : 'disabled'">
                <i class="material-icons">clear</i>
              </button>
            </div>
            <div class="input-wrapper">
              <input type="search" :name="searchName" v-model="term" :placeholder="placeholder">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import Fuse from 'fuse.js';
import IFuseOptions = Fuse.IFuseOptions;

export default defineComponent({
  name: "vue-search",
  props: ['searchName', 'placeholder', 'searchData', 'searchKeys'],
  emits: ['search:clear', 'search:result'],
  methods: {
    clearSearch() {
      this.term = '';
      this.$emit('search:clear');
    },
    emitSearchResults(searchResults: any[]){
      this.$emit('search:result', {
        searchResults: searchResults
      })
    }
  },
  watch: {
    term(newTerm) {
      if(newTerm.length === 0){
        this.clearSearch()
      }

      if (newTerm.length < 3) return;

      if (this.timer) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => {
        const searchResults = this.fuse.search(newTerm).map(result => result.item)
        this.emitSearchResults(searchResults)
      }, 300)
    },
    searchData(newData) {
      this.fuse = new Fuse(newData, this.fuseOptions)
      if(this.term.length === 0){
        this.clearSearch()
      } else {
        const searchResults = this.fuse.search(this.term).map(result => result.item)
        this.emitSearchResults(searchResults)
      }
    }
  },
  data() {
    return {
      term: '',
      timer: null as ReturnType<typeof setTimeout> | null
    }
  },
  setup(props) {
    const fuseOptions: IFuseOptions<unknown> = {
      threshold: 0.2,
      keys: props.searchKeys
    }
    const fuse = new Fuse(props.searchData, fuseOptions)
    return {
      fuse,
      fuseOptions
    }
  }
})
</script>
