import {Controller} from 'stimulus'
import {DataTable} from "simple-datatables";
import M from "materialize-css";
import {mattDestroy, mattInit} from "../matt_init";

export default class extends Controller {
  static targets = ['table']

  connect() {
    // Order datatable
    this.tableInstance = new DataTable(this.tableTarget, {
      'columns': [
        {select: 2, sort: 'desc'},
        {select: 8, sortable: false}
      ],
      'labels': DataTable.labels,
      'perPage': 20,
      'perPageSelect': [20, 40, 60, 80, 100],
      'fixedColumns': false
    });
    M.FormSelect.init(this.tableInstance.wrapper.querySelectorAll('select', {
      dropdownOptions: {
        container: document.body
      }
    }));

    this.tableInstance.on('datatable.init', this.reinitMaterialize.bind(this));
    this.tableInstance.on('datatable.page', this.reinitMaterialize.bind(this));
    this.tableInstance.on('datatable.perpage', this.reinitMaterialize.bind(this));
    this.tableInstance.on('datatable.search', this.reinitMaterialize.bind(this));
  }

  reinitMaterialize() {
    mattDestroy(this.tableInstance.wrapper);
    mattInit(this.tableInstance.wrapper);
  }

  disconnect() {
    this.tableInstance.destroy();
  }
}
