import {Controller} from 'stimulus'
import M from "materialize-css";

export default class extends Controller {
  static values = {
    tags: Array,
    autocomplete: Array
  }

  connect() {
    const currentTags = []
    this.tagsValue.map(function (el, index) {
      if (el) {
        currentTags[index] = {tag: el}
      }
    })
    const autocompleteData = {}
    this.autocompleteValue.map(function (x) {
      autocompleteData[x] = null
    })
    const options = {
      data: currentTags,
      autocompleteOptions: {data: autocompleteData, minLength: 1},
      limit: Infinity,
      placeholder: I18n.t('insert_tag')
    }
    this.instance = M.Chips.init(this.element, options);
  }

  disconnect() {
    this.instance.destroy();
  }
}
