import '../stylesheets/application.scss';
// eslint-disable-next-line no-undef
import * as ActiveStorage from '@rails/activestorage'

require("@rails/ujs").start();
require.context('../images', true);

ActiveStorage.start();

global.Chart = require('chart.js');
M.Datepicker.defaults.i18n = I18n.t('datepicker');

import ColorPallete from "../javascript/color_pallete"
global.ColorPallete = ColorPallete

const Turbolinks = require("turbolinks")
Turbolinks.start()

import "selectize"

import "../javascript/controllers"
import {mattDestroy, mattInit} from "../javascript/matt_init";
import M from "materialize-css";
import {DataTable} from "simple-datatables";

DataTable.labels = {
  perPage: `${I18n.t('datatables.list_records')}: {select}`,
  noRows: I18n.t('datatables.no_data_in_table'),
  placeholder: I18n.t('datatables.search'),
  info: `${I18n.t('datatables.listing')} {start} ${I18n.t('datatables.up_to')} {end} ${I18n.t('datatables.from')} {rows} ${I18n.t('datatables.records')}`
}

document.addEventListener('turbolinks:load', () => {
  mattInit();
  // auto open alert modal
  let alertModal = document.querySelector('#alert-modal');
  if(alertModal != null) {
    let matInst = M.Modal.getInstance(alertModal);
    matInst.open();
  }
});

document.addEventListener('turbolinks:before-cache', () => {
  mattDestroy();
})
