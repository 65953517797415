<template>
  <div class="row m-bottom-2" v-if="order.freeTransportationLimit(customer) - order.totalProductsPrice() > 0">
    <div class="col s12">
      <div class="transportation-notice">
        <i class="material-icons left">info_outline</i>
        {{ freeTransportationMessage }}
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col s12">
      <table class="order-summary-table bordered">
        <thead>
        <tr>
          <th>{{ t('code') }}</th>
          <th>{{ t('name') }}</th>
          <th>{{ t('product_variant') }}</th>
          <th class="right-align">{{ t('price') }}</th>
          <th>{{ t('your_discounts') }}</th>
          <th class="right-align">{{ t('your_price') }}</th>
          <th>{{ t('dph') }}</th>
          <th class="quantity">{{ t('total_pcs') }}</th>
          <th>{{ t('pieces_price') }}</th>
          <th class="right-align">{{ t('subtotal') }}</th>
        </tr>
        </thead>
        <tbody>
        <template v-for="row in orderRows">
          <tr>
            <td :rowspan="row.numRows">{{ row.code }}</td>
            <td :rowspan="row.numRows">{{ row.name }}</td>
            <td :rowspan="row.numRows">{{ row.variant }}</td>
            <td class="basic-price right-align">{{ row.unitprice }}</td>
            <td>
              <a v-for="discount in row.discounts" :href="row.explainDiscountUrl"
                 target="_blank"
                 class="btn btn-discount waves-effect waves-light z-depth-0 tooltipped"
                 data-position="top" :data-tooltip="discount.tooltip"
                 :class="discount.colorClass">
                {{ discount.discount }}
                <i class="material-icons">remove_red_eye</i>
              </a>
            </td>
            <td class="right-align">{{ row.discountedPrice }}</td>
            <td>{{ row.tax }}%</td>
            <td class="quantity" :rowspan="row.numRows">
              <div class="input-group" v-if="row.inStock">
                <button type="button" class="btn blue-grey lighten-3 button-plus-minus"
                        @click="subtractQuantity(row.id)">
                  -
                </button>
                <input type="number" :name="`buy[${row.id}]`" :max="row.stock" min="0"
                       placeholder="0" class="item-quantity"
                       v-model.number="order.quantities[row.id]">
                <button type="button" class="btn blue-grey lighten-3 button-plus-minus"
                        @click="addQuantity(row.id, row.stock)">
                  +
                </button>
              </div>
            </td>
            <td class="hide-prices">
              {{ row.undiscountedQuantity }}
            </td>
            <td class="right-align hide-prices">
              {{ row.totalPrice }}
            </td>
          </tr>
          <tr v-if="row.discountedQuantity > 0">
            <td class="basic-price right-align">{{ row.unitprice }}</td>
            <td>{{ t('gift') }}</td>
            <td class="right-align">{{ formatCurrency(0) }}</td>
            <td>{{ row.tax }}%</td>
            <td class="hide_prices">{{ row.discountedQuantity }}</td>
            <td class="right-align hide_prices">{{ formatCurrency(0) }}</td>
          </tr>
        </template>
        <tr class="blue-grey lighten-5" v-if="additionalDiscountPercent > 0">
          <td></td>
          <td>{{ t('additional_discount') }}</td>
          <td></td>
          <td></td>
          <td class="hide-prices">{{ additionalDiscountPercent }}</td>
          <td class="hide-prices right-align"> {{ formatCurrency(additionalDiscountAmount) }} </td>
          <td></td>
          <td></td>
          <td></td>
          <td class="right-align hide-prices">
            {{ formatCurrency(additionalDiscountAmount) }}
          </td>
        </tr>
        <tr class="blue-grey lighten-5" v-if="rounding !== 0">
          <td></td>
          <td>{{ t('haler_settlement') }}</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td class="right-align hide-prices">
            {{ formatCurrency(rounding) }}
          </td>
        </tr>
        </tbody>
      </table>
      <table class="order-summary-table">
        <tfoot>
        <tr>
          <td class="col-absorber"></td>
          <td class="total-price">
            {{ t('items_without_vat') }}
            <span class="hide-prices">
                {{ formatCurrency(order.totalProductsPrice()) }}
              </span>
          </td>
        </tr>
        <tr>
          <td class="col-absorber"></td>
          <td class="total-price">
            {{ t('postage') }}
            <span class="hide_prices">
              {{ formatCurrency(order.transportationPrice(customer)) }}
            </span>
          </td>
        </tr>
        <tr>
          <td class="col-absorber"></td>
          <td class="total-price">
            {{ t('total_without_dph') }}
            <span class="hide-prices">
              {{ formatCurrency(order.totalPriceWithRounding(customer)) }}
            </span>
          </td>
        </tr>
        <tr>
          <td class="col-absorber"></td>
          <td class="vat">
            {{ t('dph') }}
            <span class="hide-prices">
              {{ formatCurrency(order.VATAmount(customer)) }}
            </span>
          </td>
        </tr>
        <tr>
          <td class="col-absorber"></td>
          <td class="total-price-vat">
            {{ t('total_with_dph') }}
            <span class="hide-prices">
              {{ formatCurrency(order.totalPriceWithVATAndRounding(customer)) }}
            </span>
          </td>
        </tr>
        </tfoot>
      </table>
    </div>
  </div>
  <div class="row d-flex">
    <div class="col s12 m6 m-top-2 stretch">
      <div class="card blue-grey lighten-5 z-depth-0">
        <div class="card-content">
          <span class="card-title">
            <i class="material-icons tiny">home</i>
            {{ t('delivery_address') }}
          </span>
          <div class="input-field" id="select_address">
            <select class="material" name="order[address_id]" id="order_address_id" tabindex="-1">
              <option value=""> ••• {{t('no_specific_address')}} </option>
              <option v-for="address in customerAddresses" :value="address.id" :selected="order.address_id === address.id">{{ address.dropdown_name }}</option>
            </select>
            <br>
            <div id="toggle-address" class="btn blue-grey lighten-1 waves-effect waves-light m-top-0 z-depth-0" @click="displayAddressForm = !displayAddressForm">
              {{ t('new_address') }}
              <i class="material-icons right">keyboard_arrow_down</i>
            </div>
            <div v-if="customer.default_address && displayAddressForm" >
              <div class="input-field">
                <input type="text" name="address[firstname]" id="address_firstname" :value="customer.default_address.firstname" form="new_address_form">
                <label for="address_firstname">{{ t('firstname') }}</label>
              </div>
              <div class="input-field">
                <input type="text" name="address[lastname]" id="address_lastname" :value="customer.default_address.lastname" form="new_address_form">
                <label for="address_firstname">{{ t('lastname') }}</label>
              </div>
              <div class="input-field">
                <input type="text" name="address[address1]" id="address_address1" :value="customer.default_address.address1" form="new_address_form">
                <label for="address_firstname">{{ t('address1') }}</label>
              </div>
              <div class="input-field">
                <input type="text" name="address[postcode]" id="address_postcode" :value="customer.default_address.postcode" form="new_address_form">
                <label for="address_firstname">{{ t('postcode') }}</label>
              </div>
              <div class="input-field">
                <input type="text" name="address[city]" id="address_city" :value="customer.default_address.city" form="new_address_form">
                <label for="address_firstname">{{ t('city') }}</label>
              </div>
              <div class="input-field">
                <input type="text" name="address[phone]" id="address_phone" :value="customer.default_address.phone" form="new_address_form">
                <label for="address_firstname">{{ t('phone') }}</label>
              </div>
              <div class="input-field">
                <button class="btn waves-effect waves-light m-top-1 m-bottom-1" type="submit" name="commit" form="new_address_form">
                  {{ t('add_new_address') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col s12 m6 m-top-2 stretch">
      <div class="card blue-grey lighten-5 z-depth-0">
        <div class="card-content">
          <span class="card-title">
            <i class="material-icons tiny">local_shipping</i>
            {{ t('carrier') }}
          </span>
          <p>
            <input type="hidden" name="order[carrier_id]" value="">
          </p>
          <p v-for="(carrier, idx) in carriers">
            <label :for="`order_carrier_id_${idx + 1}`">
              <input :id="`order_carrier_id_${idx + 1}`" class="with-gap" type="radio" :value="carrier.id" name="order[carrier_id" :checked="order.carrier_id === carrier.id">
              <span>
                {{ carrier.name }}
              </span>
            </label>
          </p>
          <p v-if="locale !== 'pl'">
            <br>
            {{ t('delivery_price_is') }}
            <br>
            <em>
              {{ t('free_delivery_for') }}
            </em>
          </p>
          <span class="card-title m-top-2">
            <i class="material-icons tiny">payment</i>
            {{ t('payment') }}
          </span>
          <p><input type="hidden" name="order[payment_type_id]" value=""></p>
          <p v-for="(payment, idx) in paymentTypes">
            <label :for="`order_payment_type_id_${idx + 1}`">
              <input :id="`order_payment_type_id_${idx + 1}`" class="with-gap" type="radio" :value="payment.id" name="order[payment_type_id]" :checked="payment.id === order.payment_type.id" @change="paymentTypeChange" :data-name="payment.name" :data-abra-code="payment.abra_code">
              <span>
                {{ payment.name }}
              </span>
            </label>
          </p>
          <p v-if="customer.overdue">
            <br>
            <i class="material-icons left red-text">warning</i>
            {{ t('not_paid_invoce') }}
          </p>
          <p v-if="locale === 'cs'">
            <br>
            <i class="material-icons left">info_outline</i>
            <em>{{ t('additional_discount_cash_on_delivery') }}</em>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {formatCurrency} from "utils";
import {Order} from "models/order";
import {Customer} from "types/customer";
import {OrderOptions} from "types/order_options";
import {Product} from "types/product";
import {OrderItem} from "models/order_item";
import {CustomerDiscount} from "types/customer_discount";
import {mattDestroy, mattInit} from "matt_init";
import {Address} from "types/address";

const globalAny: any = global;

interface OrderRow {
  id: number;
  numRows: number;
  discountedQuantity: number;
  quantity: number;
  undiscountedQuantity: number;
  code: string;
  name: string;
  variant: string;
  unitprice: string;
  discounts: { colorClass: string; discount: string; tooltip: string; }[];
  discountedPrice: string;
  tax: string;
  totalPrice: string;
  stock: number;
  inStock: boolean;
}

export default defineComponent({
  name: "vue-order-summary",
  props: ['customerUrl', 'orderUrl', 'productsUrl', 'carriers', 'paymentTypes'],
  methods: {
    t(arg: string, opts?: any) {
      return globalAny.I18n.t(arg, opts)
    },
    loadOrderData() {
      fetch(this.orderUrl, {
        headers: {Accept: 'application/json'},
        cache: 'no-cache'
      }).then(response => response.json()).then((orderOptions: OrderOptions) => {
        fetch(this.customerUrl, {
          headers: {Accept: 'application/json'},
          cache: 'no-cache'
        }).then(response => response.json()).then((customer: Customer) => {
          fetch(this.productsUrl, {
            headers: {Accept: 'application/json'}
          }).then(response => response.json()).then((products: Product[]) => {
            this.order = new Order(orderOptions);
            this.products = products;
            this.customer = customer;
            this.order.loadOrderItems(this.products, this.customer, true)
          })
        })
      })
    },
    subtractQuantity(productId: number) {
      if (this.order.quantities[productId] != null && this.order.quantities[productId] > 0)
        this.order.quantities[productId] -= 1;
    },
    addQuantity(productId: number, max: number) {
      if (this.order.quantities[productId] == null) {
        this.order.quantities[productId] = 0;
      }
      if (this.order.quantities[productId] < max)
        this.order.quantities[productId] += 1;
    },
    discountColor(discount: CustomerDiscount): string {
      const discountItems = this.order.itemsForDiscount(discount);

      let combinedQuantity = 0;
      discountItems.forEach(discountItem => {
        combinedQuantity += discountItem.quantity - discountItem.gifted_quantity
      });

      if (combinedQuantity >= discount.min_num_products) {
        return 'light-green lighten-2';
      } else {
        return 'grey lighten-1'
      }
    },
    discountTooltip(discount: CustomerDiscount, productId: number) {
      if (this.order.quantities == null) return '';

      const discountItems = this.order.itemsForDiscount(discount);

      let combinedQuantity = 0;
      discountItems.forEach(discountItem => {
        combinedQuantity += discountItem.quantity - discountItem.gifted_quantity
      });

      if (combinedQuantity >= discount.min_num_products) return this.t('discount_active');
      const missingQuantity = discount.min_num_products - combinedQuantity;
      return `${this.t('buy_at_least')} ${missingQuantity} ${this.t('pluralized_products', {count: missingQuantity})} ${this.t('and_get_discount')}`
    },
    paymentTypeChange(event: Event){
      const target: HTMLInputElement = event.currentTarget as HTMLInputElement;
      const name = target.dataset.name == null ? "" : target.dataset.name;
      const abraCode = target.dataset.abraCode == null ? "" : target.dataset.abraCode;

      if(target.checked){
        this.order.payment_type = { id: Number.parseInt(target.value), name: name, abra_code: abraCode }
      }
    },
    formatCurrency
  },
  computed: {
    orderRows(): OrderRow[] {
      if (this.order.order_items == null) return [];

      return this.order.order_items.map((orderItem: OrderItem) => {
        const discountedQuantity = orderItem.discountedQuantity();
        const discountedUnitPrice = orderItem.discountedUnitprice();
        const product = orderItem.product;

        return {
          id: product.id,
          numRows: discountedQuantity > 0 ? 2 : 1,
          discountedQuantity: discountedQuantity,
          quantity: orderItem.quantity,
          undiscountedQuantity: orderItem.quantity - discountedQuantity,
          code: product.code,
          name: product.name,
          variant: product.variant,
          unitprice: this.formatCurrency(orderItem.unitprice),
          discounts: product.customer_discounts.map(discount => {
            return {
              colorClass: this.discountColor(discount),
              discount: discount.discount,
              tooltip: this.discountTooltip(discount, product.id)
            }
          }),
          discountedPrice: this.formatCurrency(discountedUnitPrice),
          tax: product.tax,
          totalPrice: this.formatCurrency(discountedUnitPrice * (orderItem.quantity - discountedQuantity)),
          inStock: product.in_stock,
          stock: product.stock
        } as OrderRow
      });
    },
    freeTransportationMessage() {
      const remainingAmount = formatCurrency(this.order.freeTransportationLimit(this.customer) - this.order.totalProductsPrice());

      return `${globalAny.I18n.t('buy_for_at_least')} ${remainingAmount} ${globalAny.I18n.t('without_vat')} ${globalAny.I18n.t('receive_free_transportation')}`
    },
    customerAddresses(): Address[]{
      if(this.customer.addresses == null) return [];

      return this.customer.addresses.filter(address => !address.billing);
    },
    locale(){
      return globalAny.I18n.locale;
    },
    additionalDiscountPercent(): number{
      return this.order.additionalDiscountPercent();
    },
    additionalDiscountAmount(): number{
      return -this.order.additionalDiscountAmount();
    },
    rounding(): number{
      return this.order.rounding();
    }
  },
  data() {
    return {
      order: new Order(),
      customer: {} as Customer,
      products: [] as Product[],
      timer: null as ReturnType<typeof setTimeout> | null,
      displayAddressForm: false
    }
  },
  mounted() {
    this.loadOrderData();
  },
  beforeUpdate() {
    let el = this.$el;
    while (el != null && !el.querySelectorAll) {
      el = el.parentElement;
    }
    if (el == null) return;

    mattDestroy(el);
  },
  updated() {
    let el = this.$el;
    while (el != null && !el.querySelectorAll) {
      el = el.parentElement;
    }
    if (el == null) return;

    mattInit(el);
  },
  created() {
    this.$watch(
        () => this.order.quantities,
        () => {
          if (this.timer) {
            clearTimeout(this.timer);
          }
          this.timer = setTimeout(() => {
            this.order.loadOrderItems(this.products, this.customer, true);
          }, 200)
        },
        {deep: true}
    )
  }
})
</script>

<style scoped>

</style>
