import { Controller } from 'stimulus'
import AutoComplete from '@tarekraafat/autocomplete.js'
import {AutoCompleteUtils} from "../utils";
import Turbolinks from 'turbolinks'

export default class extends Controller{
  static targets = ['input']

  static values = {
    url: String,
    redirectUrl: String
  }

  connect(){
    new AutoComplete({
      data: {
        src: async () => {
          const query = this.inputTarget.value;
          const source = await fetch(`${this.urlValue}?term=${encodeURIComponent(query)}`);
          return await source.json();
        },
        key: ['title']
      },
      selector: () => {
        return this.inputTarget;
      },
      highlight: true,
      placeHolder: I18n.t('customer_select.placeholder'),
      resultsList: {
        container: source => {
          console.log(source);
          AutoCompleteUtils.createAbsoluteDropdown(source, this.inputTarget)
        },
        destination: () => {
          return document.body;
        },
        className: 'collection autocomplete',
        element: 'div'
      },
      resultItem: {
        content:  AutoCompleteUtils.createResultItem,
        element: "a",
        className: 'collection-item'
      },
      onSelection: feedback => {
        Turbolinks.visit(`${this.redirectUrlValue}?customer_id=${feedback.selection.value.id}`)
      }
    })

    this.inputTarget.addEventListener('keydown', AutoCompleteUtils.bindDefaultKeyEvents)
  }
}
